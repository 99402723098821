import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { Role, RoleCode } from '@app/shared/types/role.type';
import { ApiResponse } from '@core/api';
import { StorageWrapper } from '@core/storage';
import { environment } from '@environments/environment';
import { Observable, finalize, firstValueFrom, from, map, mergeMap } from 'rxjs';
import { IAuthService } from './auth';

export interface AuthenticationData extends ExpiredAccountInfo {
  AccessToken: string;
  UserID: string;
  Username: string;
  Token: string;
}

export interface Profile {
  UserID: string;
  Username: string;
  Email: string;
  Status: string;
  BID: number;
  PhoneNumber: string;
  IsPasswordRequiredChange: boolean;
  IsPasswordExpired: boolean;
  LastLoginAt: Date;
  DisplayName: string;
  BUName: string;
  Roles: RoleCode[];
}

export interface Config {
  TurnstileSiteKey?: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {
  public Profile$!: Observable<ApiResponse<Profile>>;
  public Config$!: Observable<ApiResponse<Config>>;

  public expiredAccountInfo: ExpiredAccountInfo | null = null;

  setExpiredAccountInfo(value: ExpiredAccountInfo) {
    this.expiredAccountInfo = value;
  }
  getExpiredAccountInfo(): ExpiredAccountInfo | null {
    return this.expiredAccountInfo;
  }

  constructor(private readonly router: Router, private readonly http: HttpClient, private dialogService: DialogService) { }

  login({ Email, Password, TurnstileToken }: { Email: string, Password: string, TurnstileToken: string }) {
    return this.http.post<ApiResponse<AuthenticationData>>(`${environment.apiUrl}/auth/authentication`, { Email, Password, TurnstileToken })
  }

  logout() {
    return this.http.post<ApiResponse<unknown>>(`${environment.apiUrl}/account/logout`, {})
      .pipe(
        finalize(() => {
          StorageWrapper.clear();
          this.router.navigate(['/auth/login']);
        })
      ).subscribe();
  }

  sessionExpireNotify(rememberRoute = false) {
    const navigateOption = rememberRoute ? { queryParams: { returnUrl: this.router.url } } : undefined;
    StorageWrapper.clear();
    return this.dialogService.ShowInvalidSession().pipe(
      mergeMap(v => this.router.navigate(['/auth/login'], navigateOption))
    )
  }

  getProfile(force = false) {
    if (!this.Profile$ || force) {
      this.Profile$ = from(firstValueFrom(this.http.post<ApiResponse<Profile>>(`${environment.apiUrl}/account/profile`, {})));
    }
    return this.Profile$;
  }

  hasRole(role: Role) {
    return this.getProfile().pipe(map(response => response?.Data?.Roles?.includes(Role[role] as RoleCode)));
  }

  getConfig() {
    if (!this.Config$) {
      this.Config$ = from(firstValueFrom(this.http.post<ApiResponse<Config>>(`${environment.apiUrl}/auth/getclientconfig`, {})));
    }
    return this.Config$;
  }
}

export interface ExpiredAccountInfo {
  Email?: string,
  PasswordUpdatedAt?: string | null,
  ExpirePasswordDateTime?: string | null
}
