
export enum StorageKey {
  ACCESS_TOKEN = 'AccessToken',
  REFRESH_TOKEN = 'RefreshToken',
  SESSION_ID = 'X-SESSIO-ID',

  CURRENT_USER = 'CurrentUser',
  CURRENT_LANG = 'language',
}

export class StorageWrapper {
  private static localStorage: Storage;
  private static isEnabled: boolean;

  static {
    StorageWrapper.isEnabled = !!window.localStorage;
    StorageWrapper.localStorage = window.localStorage;

    if (!StorageWrapper.isEnabled) {
      console.error('Current browser does not support Local Storage');
    }

  }

  static set(key: StorageKey, value: string): void {
    if (StorageWrapper.isEnabled) {
      StorageWrapper.localStorage.setItem(key, value);
    }
  }

  static get(key: StorageKey): string {
    if (!StorageWrapper.isEnabled) {
      return '';
    }

    return StorageWrapper.localStorage.getItem(key) ?? '';
  }

  static setObject(key: StorageKey, value: unknown): void {
    if (!StorageWrapper.isEnabled) {
      return;
    }
    StorageWrapper.localStorage.setItem(key, JSON.stringify(value));
  }

  static getObject<TType = unknown>(key: StorageKey): TType | null {
    if (!StorageWrapper.isEnabled) {
      return null;
    }
    const value = StorageWrapper.localStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value) as TType;
  }

  static remove(key: StorageKey): void {
    if (!StorageWrapper.isEnabled) {
      return;
    }
    StorageWrapper.localStorage.removeItem(key);
  }

  static clear(): void {
    StorageWrapper.localStorage.clear();
  }
}
